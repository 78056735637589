<template>
  <div class="classes">
    <div class="classes__list">
      <archive-class-list-item v-for="cl of classes" v-bind:key="cl.id"
          class="classes__list__item"
          :class-name="cl.fullName"
          @click.native="goToArchiveClass(cl)"/>
    </div>
  </div>
</template>

<script>
import ArchiveClassListItem from "../../../components/Archive/ArchiveClassListItem";
export default {
  name: "ArchiveListClassesView",
  components: {ArchiveClassListItem},
  computed:{
    classes(){
      if (this.$store.getters.PROFILE == null){
        return []
      }
      return this.$store.getters.PROFILE.myClasses
    }
  },
  methods:{
    async goToArchiveClass(cl) {
      console.log(cl)
      await this.$store.dispatch("parseArchiveBySchoolClass",{
        educationObjectId: cl.educationObjectId,
        schoolClassId: cl.id
      })
      await this.$store.commit("SET_SCHOOL_CLASS_ARCHIVE",cl)
      await this.$router.push({name:"ArchiveListView",params:{classId : cl.id}})
    }
  }
}
</script>

<style scoped lang="scss">
  .classes{
    &__list{
      margin-top: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media(767){
        margin-top: 14px;
      }

      &__item{
        margin-top: 20px;

        @include media(767){
          margin-top: 10px;

        }
      }
    }
  }
</style>
