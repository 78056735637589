<template>
  <div class="item"
       ref="item"
       v-touch:start="onTapStart"
       v-touch:end="onTapEnd">
    <ClassIcon
        class="item__icon"
        :text="className"/>
    <p class="item__text">Смотреть запись</p>
  </div>
</template>

<script>
import ClassIcon from "../../assets/vue-icons/Class/ClassIcon";
export default {
  name: "ArchiveClassListItem",
  components: {ClassIcon},
  props:{
    className : String
  },
  methods:{

    onTapStart(){
      this.$refs.item.style.background = "#fbfbfb"
    },


    onTapEnd(){
      this.$refs.item.style.background = "#ffffff"
    }
  }
}
</script>

<style scoped lang="scss">
  .item{
    background: #FFFFFF;
    border: 1px solid rgba(198,206,212,0.50);
    border-radius: 6px;
    width: 500px;
    height: 64px;
    display: flex;
    align-items: center;


    @include media(767){
      width: 345px;

    }

    &__icon{
      margin-left: 23px;
    }

    &__text{
      margin-left: 19px;
      font-family: Roboto, "sans-serif";
      font-weight: 500;
      font-size: 16px;
      color: #333942;
      text-align: left;
      line-height: 19px;
    }
  }
</style>
